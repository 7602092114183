<template>
  <div class="event-participants-grid">
    <div class="row">
      <div class="col-12">
        <!-- <sprgrid :data="participants" :columns="columns" translation-key="eventParticipants" v-on="$listeners" @show="onShowAdditionalModalInfo" /> -->
        <table class="grid-table">
          <thead class="grid-column">
            <tr>
              <th v-for="(column, i) in columns" :key="i"
                  :class="getCssClass(column)"
                  @click="sortBy(column)"
              >
                {{ formatHeader(column) }}
                <span class="arrow" :class="sortOrders[column.name] > 0 ? 'asc' : 'dsc'" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!filteredData || !filteredData.length">
              <td class="nodata" :colspan="columns.length">
                {{ $t('userAdmin.noResults') }}
              </td>
            </tr>
            <tr v-for="(rowItem, i) in filteredData" :key="i">
              <td v-for="(columnKey, j) in columns" :key="j" :class="getCssClass(columnKey)">
                <div v-if="hasColumnCallBack(columnKey)">
                  <div v-if="hasItem(columnKey, rowItem)">
                    <font-awesome-icon class="grid-icon" :icon="columnKey.iconName" @click="onShowAdditionalModalInfo(rowItem)" />
                  </div>
                </div>
                <div v-else-if="columnKey.name === 'participated'" class="text-center">
                  <input type="checkbox" :checked="rowItem[columnKey.name]" @change="$emit('change', rowItem)">
                </div>
                <div v-else-if="typeof rowItem[columnKey.name] === 'boolean'" class="text-center">
                  <input type="checkbox" :checked="rowItem[columnKey.name]" @change="$emit('changeRemoval', rowItem)">
                </div>
                <div v-else>
                  {{ formatData(rowItem[columnKey.name]) }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import _ from 'lodash'
  import moment from 'moment'
  

  export interface TemplateComponentData {
    sortKey: string
    sortOrders: any
    columns: Array<any>
    filterKey: String
    translationKey: String
  }
  export default Vue.extend({
    name: 'EventParticipantsGrid',
    props: {
      value: {
        type: Array,
        default: () => []
      }
    },
    data (): TemplateComponentData {
      return {
        sortKey: '',
        sortOrders: {},
        columns: [{
                    name: 'name'
                  },
                  {
                    name: 'created',
                    cssClass: 'd-none d-md-table-cell'
                  },
                  {
                    name: 'phone',
                    cssClass: 'd-xl-table-cell'
                  },
                  {
                    name: 'email',
                    cssClass: 'd-xl-table-cell'
                  },
                  {
                    name: 'postalCode',
                    cssClass: 'd-none d-lg-table-cell'
                  },
                  {
                    name: 'role',
                    cssClass: 'd-none d-lg-table-cell'
                  },
                  {
                    name: 'participated',
                    cssClass: 'd-none d-lg-table-cell'
                  },
                  {
                    name: 'extra',
                    cssClass: 'd-xl-table-cell',
                    callBack: 'onShowAdditionalModalInfo',
                    iconName: 'info-circle'
                  },
                  {
                    name: 'remove',
                    cssClass: 'd-none d-lg-table-cell'
                  }],
        filterKey: '',
        translationKey: 'eventParticipants'
      } as TemplateComponentData
    },
    created () {
      this.columns.forEach((column) => {
        this.sortOrders[column.name] = 1
      })        
    },
    computed: {
      filteredData (): any {
        const sortKey = this.sortKey
        const filterKey = this.filterKey && this.filterKey.toLowerCase()
        const order = this.sortOrders[sortKey] || 1
        let data = this.participants

        if (filterKey) {
          data = data.filter(function (row) {
            return Object.keys(row.name).some(function (key) {
              return (
                String(row[key])
                  .toLowerCase()
                  .indexOf(filterKey) > -1
              )
            })
          })
        }

        if (sortKey) {
          data = data.slice().sort(function (a, b) {
            a = a[sortKey]
            b = b[sortKey]
            return (a === b ? 0 : a > b ? 1 : -1) * order
          })
        }

        return data
      },
      participants (): any {
        let gridData = [] as any
        _.forEach(this.value, (role) => {
          _.forEach(role.participants, (participant) => {
            gridData.push({
              id: participant.id,
              role: role.name,
              name: [participant.lastName, participant.firstName].join(' '),
              created: moment(participant.created),
              phone: participant.phone,
              email: participant.email,
              postalCode: participant.zipCode,
              moreInfo: participant.moreInfo,
              extra: participant.moreInfo,
              participated: participant.participated,
              remove: false
            })
          })
        })
        return gridData
      }
    },
    methods: {
      formatData (value) {
        if (moment.isMoment(value)) {
          return moment(value).format('DD.MM.YYYY HH.mm')
        }
        return value
      },
      hasColumnCallBack (columnKey) {
        let hasCallBack = false

        if (columnKey.callBack) {
          hasCallBack = true
        }

        return hasCallBack
      },
      hasItem (columnKey, rowItem) {
        const columnName = columnKey.name

        if (rowItem[columnName]) {
          return true
        }

        return false
      },
      getCssClass (col) {
        let c = col.cssClass
        if (this.sortKey === col.name) {
          c += ' active'
        }
        if (col.iconName) {
          c += ' has-icon'
        }

        return c
      },
      sortBy: function (key) {
        this.sortKey = key.name
        this.sortOrders[key.name] = this.sortOrders[key.name] * -1
      },
      formatHeader (obj) {
        if (this.translationKey) {
          return this.$t(`${this.translationKey}.${obj.name}`)
        }
        return obj.name.charAt(0).toUpperCase() + obj.slice(1)
      },

      getMessageBodyWithLineBreaks (name) {
        const arr = [] as any
        const breaksArr = name.split('\n')
        for (let i = 0; i < breaksArr.length; i++) {
          const el = this.$createElement('div', breaksArr[i])
          arr.push(el)
        }
        return arr
      },
      onParticipationChanged (val) {
        this.$emit('change', val)
      },
      onRemovalChanged (val) {
        this.$emit('changeRemoval', val)
      },
      onShowAdditionalModalInfo (item) {
        console.log(item)
        if (item.extra) {
          this.$bvModal.msgBoxOk(this.getMessageBodyWithLineBreaks(item.extra), {
            title: item.name,
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'success',
            headerClass: 'modal-body',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
        }
      },
      displayTime (value) {
        if (value) {
          return moment.utc(value).local().format('D.M.YYYY H.mm')
        }
        return ''
      }
    }
  })
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";

.modal-body {
  div {
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
}
.modal-footer {
  .btn-success {
    border-radius: 20px;
    background-color: $brand-red;
    color: $brand-white;
    text-transform: uppercase;
    border-color: $brand-red;

    &:hover,
    &:active,
    &:focus {
      background-color: $brand-red !important;
    }
  }
}

.event-participants-grid {
  padding: 0 -15px;
  width: 100%;
  overflow-x: auto;
  .grid-column{
    overflow: auto;
    font-family: $font-bold;
    text-transform: uppercase;
    font-size: 14px;
  }
  .grid-icon {
    color: $brand-red;
    font-size: 16px;
  }
  .grid-table {
    border: none;
    border-radius: 3px;
    background-color: #fff;
    overflow: auto;
    width: 100%;
  }
}

th {
  background-color: $brand-grey2;
  color: $brand-red;
  cursor: pointer;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: auto;
}

td {
  background-color: #f9f9f9;
  vertical-align: top;

  &.nodata {
    text-align: center;
  }
}

th,
td {
  min-width: 20px;
  padding: 10px 10px;
  &.has-icon {
    text-align: center;
  }
}

th.active {
  color: $brand-red;
}

th.active .arrow {
  opacity: 1;
  &.asc {
    border-bottom: 4px solid $brand-red;
  }
  &.dsc {
    border-top: 4px solid $brand-red;
  }
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid $brand-grey8;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid $brand-grey8;
}

</style>