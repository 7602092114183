import { render, staticRenderFns } from "./UserSimpleSearchResult.vue?vue&type=template&id=1f787872&scoped=true"
import script from "./UserSimpleSearchResult.vue?vue&type=script&lang=ts"
export * from "./UserSimpleSearchResult.vue?vue&type=script&lang=ts"
import style0 from "./UserSimpleSearchResult.vue?vue&type=style&index=0&id=1f787872&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f787872",
  null
  
)

export default component.exports