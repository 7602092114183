<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .groupexistingmember {
    padding:5px;
    .user-name {
      .name {
        color: #CC0000;
        font-weight: bold;
        text-transform: uppercase;
      }
      .isadmin {
        svg {
          color:$brand-success;
          margin-left: 5px;
        }
      }
      .age {
        display: block;
      }
    }

    .actions {
      text-align: right;

      .remove {
        margin-top:5px;
      }
    }

  }
</style>
<template>
  <div class="row groupexistingmember">
    <div class="col-md-5 col-12 user">
      <div class="user-img">
        <sprportrait :style="portraitStyle"
                     class="portrait-size"
        />
      </div>
      <div class="user-name">
        <span class="name">{{ member.lastName }}, {{ member.firstName }}</span>
        <span v-if="member.isAdmin" class="isadmin"><font-awesome-icon icon="star" /></span>
        <span v-if="member.age" class="age">{{ age }} {{ $t('customerSearch.years') }}</span>
        <span class="section">{{ section }}</span>
      </div>
    </div>

    <div class="col-md-7 col-12 actions text-right">
      <sprbutton
        v-if="!member.isAdmin"
        type="primary"
        :size="4"
        :title="$t('userAdmin.makeGroupAdmin')"
        class="spr-redborder button-width"
        click-event="makeAdmin"
        @makeAdmin="onMakeAdmin"
      />
      <sprbutton
        v-if="member.isAdmin"
        type="primary"
        :size="4"
        :title="$t('userAdmin.removeGroupAdmin')"
        class="spr-redborder button-width"
        click-event="removeAdmin"
        @removeAdmin="onRemoveAdmin"
      />
      <sprbutton
        type="primary"
        :size="4"
        :title="$t('userAdmin.removeFromGroup')"
        class="spr-redborder button-width remove"
        click-event="removeVolunteer"
        @removeVolunteer="onRemove"
      />
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import sprportrait from '../SPRPortrait.vue'
  import datehelper from '../../mixins/dates.js'
  import anonPortrait from '../../assets/img/anon-portrait.png'
  export default {
    name: 'Groupexistingmember',
    components: { sprbutton, sprportrait },
    mixins: [datehelper],
    props: {
      member: {
        type: Object,
        default: null
      }
    },
    data: function () {
      return {
        inProgress: false
      }
    },
    computed: {
      age () {
        return this.calculateAge(this.member.age)
      },
      section () {
        var self = this
        var section = _.find(this.$store.state.sections, (s) => {
          return s.id === self.member.sectionId
        })

        return section ? section.name : ''
      },
      portraitStyle () {
        var port = (this.$store.state.profile && this.$store.state.profile.portrait) ? this.$store.state.profile.portrait : anonPortrait
        return 'background: url(' + port + '); background-size: cover; background-position: center center;'
      }
    },
    methods: {
      onMakeAdmin () {
        this.$emit('makeAdmin', this.member)
      },
      onRemoveAdmin () {
        this.$emit('removeAdmin', this.member)
      },
      onRemove () {
        if (this.inProgress) {
          return
        }
        this.inProgress = true
        this.$emit('remove', this.member, this)
      }
    }
  }
</script>
