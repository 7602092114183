<template>
  <div class="container-fluid">
    <div class="row">
      <div class="collapse-select-container" @click="toggleAdvancedSearch">
        <span class="collapse-select">{{ showCollapsed ? $t('sectionMessages.collapseRecipients') : $t('sectionMessages.showRecipients') }}</span>
        <font-awesome-icon v-if="showCollapsed" icon="chevron-down" class="brand-red" aria-hidden="true" />
        <font-awesome-icon v-else icon="chevron-up" class="brand-red" aria-hidden="true" />
      </div>
    </div>
    <div v-if="results && showCollapsed" class="row">
      <div class="selected-result">
        <result v-for="(r, i) in results"
                :key="'resultset_' + _uid + '_' + i + '_' + r.profileId"
                :volunteer="r"
        />
      </div>
      <sprbutton v-if="hasSelectedVolunteers"
                 type="primary"
                 :disabled="!hasConsent"
                 :size="4"
                 :title="$t('userAdmin.clearList')"
                 class="clear-list-button"
                 click-event="clearVolunteerList"
                 @clearVolunteerList="onClearVolunteerList"
      />
      <checkboxinput
        v-model="hasConsent"
        class="consent-clear"
        :title="$t('userAdmin.consentClear')"
        :aria-label="$t('userAdmin.consentClear')"
      />
    </div>
  </div>
</template>

<script>
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import sprbutton from '../SPRButton.vue'
  import result from './SelectedVolunteerResult.vue'
  export default {
    name: 'SelectedVolunteerListing',
    components: { result, sprbutton, checkboxinput },
    props: {
      users: {
        type: Array,
        default: () => []
      }
    },
    data: function () {
      return {
        showCollapsed: true,
        hasConsent: false
      }
    },
    computed: {
      results: function () {
        return this.$store.state.selectedVolunteers
      },
      hasSelectedVolunteers () {
        return this.$store.state.selectedContact.length > 0
      }
    },
    methods: {
      toggleAdvancedSearch () {
        this.showCollapsed = !this.showCollapsed
      },
      onClearVolunteerList () {
        this.$store.state.selectedContact = []
        this.$store.state.selectedVolunteers = []
        this.$parent.cancelSendMessage()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  .consent-clear {
    margin-top: 11px;
    margin-left: 14px;
  }
  .collapse-select {
    display: inline-block;
    padding: 10px;
    font-size: 18px;
    text-decoration: none;
    font-family: "Signa", Sans-Serif;
    color: #747473;
    transition: color 0.33s;
  }
  .collapse-select-container{
    flex: 0 0 auto;
    align-self: center;
    margin-left: 20px;
     cursor: pointer;

    :hover {
      color: #000000;
      transition: color 0.33s;
    }
  }

  .selected-result {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .comp-user-search {
    .filter-links {
      a {
        text-transform: uppercase;
        font-weight: bold;
        color: $brand-grey7 !important;

        &.router-link-active {
          color: $brand-red !important;
        }
      }
    }

    .spinner {
      color: $brand-red;
      text-align: center;
    }
  }

   @media (max-width: $media-sm-max) {
        .selected-result {
          display: unset;
        }
    }
</style>
