<template>
  <div class="friendicon" :class="friendStateStyle" :style="style" :title="titleText">
    <div class="lettercontainer">
      <div v-if="cross" class="x x1" />
      <div v-if="cross" class="x x2" />
      <div class="lettertext">
        {{ letter }}
      </div>
    </div>
    <div v-if="image" class="friendstate" :class="friendStateStyleOrb" />
  </div>
</template>

<script>
  export default {
    name: 'FriendIcon',
    props: {
      image: {
        type: String,
        default: ''
      },
      friendname: {
        type: String,
        default: ''
      },
      state: {
        type: Number,
        default: -1
      },
      multipleFriendships: {
        type: Boolean,
        default: () => false
      },
      cross: {
        type: Boolean,
        default: () => false
      }
    },
    computed: {
      letter () {
        if (this.friendname && this.friendname.length > 0) {
          return this.friendname.substring(0, 1)
        }
        return '?'
      },
      style () {
        if (!_.isEmpty(this.image)) {
          return 'background:url(' + this.image + '); background-size: cover; background-position: center center no-repeat;'
        }
        return ''
      },
      friendStateStyle () {
        var result = (this.multipleFriendships && this.state > 1 && this.state < 5) ? 'multipleFriendships ' : ''
        if (!this.state || !this.$store.state.customerStates) return result + 'unknown'
        const cState = this.$store.state.customerStates[this.state - 1]
        return cState ? `${result}state-${this.$store.state.customerStates[this.state].text.toLowerCase()}` : `${result}unknown`
      },
      friendStateStyleOrb () {
        if (!this.state || !this.$store.state.customerStates) return 'unknown'
        const cState = this.$store.state.customerStates[this.state - 1]
        return cState ? `state-${this.$store.state.customerStates[this.state].text.toLowerCase()}` : `unknown`
      },
      titleText: function () {
        if (!this.state || !this.$store.state.customerStates) {
          return this.$t('customerStates.unknown')
        }
        if (this.state === -1) {
          return this.$t('customerStates.unknown')
        }

        return this.$t('customerStates.' + this.$store.state.customerStates[this.state]?.text)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";

  .state-pendingapproval {
    background-color: $state-pendingapproval;
    border-color: $state-pendingapproval;
  }

  .state-active {
    background-color: $state-active;
    border-color: $state-active;
  }

  .state-pendingfriend {
    background-color: $state-pendingfriend;
    border-color: $state-pendingfriend;
  }

  .state-activefriendship {
    background-color: $state-activefriendship;
    border-color: $state-activefriendship;
  }

  .state-nonactive {
    background-color: $state-nonactive;
    border-color: $state-nonactive;
  }

  .state-onhold {
    background-color: $state-onhold;
    border-color: $state-onhold;
  }

  .multipleFriendships {
    background-color: $multiple-friendships;
    border-color: $multiple-friendships;
  }

  .unknown {
    display: none;
  }

  .friendicon {
    cursor: default;
    display: inline-block;
    border-radius:50%;
    width:50px;
    height:50px;
    margin:0 auto;
    position: relative;

    .lettercontainer {
      display:flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      align-content: center;

      .lettertext {
        align-self: center;
        font-weight: bold;
        color:$brand-white;
        font-size: 1.4em;
        text-transform: uppercase;
      }
    }

    .x {
      width: 0px;
      height: 50px;
      background-color:red;
      display:inline-block;
      position: absolute;
      border: solid 2px red;
      border-radius: 5px;
    }

    .x1 {
      position: relative;
      transform: rotate(45deg);
      left: 9px;
      top: 0px;
    }

    .x2 {
      position: relative;
      transform: rotate(135deg);
      left: 5px;
      top: 0px;
    }
  }

  .friendstate {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
  }
</style>
