<template>
  <div class="volunteer-view my-2">
    <div class="col-12 grid-row" @click="clicked">
      <div class="grid-img style-img">
        <sprportrait :style="portraitStyle"
                     class="portrait-size"
        />
      </div>
      <div class="grid-name style-name">
        <span v-if="volunteer.isAdmin" class="isadmin"><font-awesome-icon icon="star" /></span>
        <span>{{ volunteer.lastName }}, {{ volunteer.firstName }}</span><br>
        <span class="more-info">{{ section }}</span>
      </div>
      <div v-if="admin && isStandardGroup" class="grid-zip style-zip">
        <span v-if="!volunteer.isAdmin && !isCurrentUser(volunteer.id)" class="action-link" @click="makeGroupAdmin">
          {{ $t('userAdmin.makeGroupAdmin') }}
        </span>
        <span v-if="volunteer.isAdmin && !isCurrentUser(volunteer.id)" class="action-link" @click="removeGroupAdmin">
          {{ $t('userAdmin.removeGroupAdmin') }}
        </span>
        <span v-if="!isCurrentUser(volunteer.id)" class="action-link" @click="showRemove = !showRemove">
          {{ $t('groups.removeVolunteer') }}
        </span>
      </div>
      <div v-if="admin && !isStandardGroup" class="grid-zip style-zip">
        <font-awesome-icon v-if="open" icon="chevron-down" class="brand-red" aria-hidden="true" />
        <font-awesome-icon v-else icon="chevron-up" class="brand-red" aria-hidden="true" />
      </div>
    </div>

    <div v-show="open" class="col-12 info">
      <div class="row">
        <div class="col-md-9 col-xs-12">
          <dl>
            <dd>{{ profileState }}</dd>
            <dt>{{ $t('profile.interestEditorHeader') }}</dt>
            <dd>{{ volunteerInterests }}</dd>
            <dt>{{ $t('profile.skillEditorHeader') }}</dt>
            <dd>{{ volunteerSkills }}</dd>
            <dt>{{ $t('profile.nativeLanguage') }}</dt>
            <dd>{{ normalizeTranslation(volunteer.nativeLanguage) }}</dd>
            <dt>{{ $t('nonIdentProfileForm.languageSpoken') }}</dt>
            <dd>{{ spokenLanguages }}</dd>
            <dt>{{ $t('adminUserEditor.workingZipCode') }}</dt>
            <dd>{{ volunteer.workingZipCode }}</dd>
            <dt>{{ $t('customerSearch.myTimes') }}</dt>
            <dd>{{ availabilityTimes }}</dd>
            <dt>{{ $t('transportation.title') }}</dt>
            <dd>{{ transportation }}</dd>
            <dt>{{ $t('profile.travelTime') }}</dt>
            <dd>{{ volunteer.travelTime }} min</dd>
            <dt>{{ $t('profile.email') }}</dt>
            <dd>{{ volunteer.email }}</dd>
            <dt>{{ $t('profile.phoneNumber') }}</dt>
            <dd>{{ phoneNumber }}</dd>
          </dl>
        </div>
        <div v-if="admin" class="col-3 col-xs-12 text-right buttons">
          <sprbutton v-if="!isCurrentUser(volunteer.id)"
                     type="primary"
                     :size="4"
                     :title="$t('groups.removeVolunteer')"
                     class="spr-redborder button-width"
                     click-event="removeVolunteer"
                     @removeVolunteer="showRemove = !showRemove"
          />
        </div>
      </div>
    </div>

    <b-modal v-model="showRemove"
             :title="$t('groups.youSure')"
    >
      <div>
        {{ $t('groups.confirmUserDelete') }} {{ volunteer.lastName }}, {{ volunteer.firstName }}?
      </div>
      <mfoot slot="modal-footer"
             :buttons="modalButtons()"
             @cancel="showRemove = !showRemove"
             @remove="onRemove"
      />
    </b-modal>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import sprportrait from '../SPRPortrait.vue'
  import mfoot from '../modals/ModalFooter.vue'
  import enumhelper from '../../mixins/enums.js'
  import anonPortrait from '../../assets/img/anon-portrait.png'
  import bitmaskhelper from '@/helpers/bitmaskhelper'

  export default {
    name: 'Volunteersview',
    components: { sprbutton, sprportrait, mfoot },
    mixins: [enumhelper],
    props: {
      volunteer: {
        type: Object,
        required: true
      },
      admin: {
        type: Boolean,
        default: true
      },
      isStandardGroup: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        showRemove: false,
        open: false
      }
    },
    computed: {
      section () {
        var self = this
        var section = _.find(this.$store.state.sections, (s) => {
          return s.id === self.volunteer.sectionId
        })
        return section ? section.name : ''
      },
      portraitStyle () {
        var port = (this.volunteer && this.volunteer.portrait && this.volunteer.portrait !== '') ? this.volunteer.portrait : anonPortrait
        return 'background: url(' + port + '); background-size: cover; background-position: center center;'
      },
      profileState: function () {
        switch (this.volunteer.profileState) {
        case 1:
          return this.$t('profile.activeOpen')
        case 2:
          return this.$t('profile.activeNoContact')
        case 3:
          return this.$t('profile.activeNoNew')
        case 4:
          return this.$t('profile.inactive')
        }
        return null
      },
      volunteerInterests: function () {
        return this.parseArray(this.volunteer.areasOfInterest, this.$store.state.profileAreasOfInterest, 'interest') || '-'
      },
      volunteerSkills: function () {
        return this.parseArray(this.volunteer.personalSkills, this.$store.state.profilePersonalSkills, 'skills') || '-'
      },
      spokenLanguages: function () {
        if (this.volunteer.spokenLanguages && this.volunteer.spokenLanguages.length) {
          const self = this
          const langs = []
          this.volunteer.spokenLanguages.forEach(function (l) {
            langs.push(self.normalizeTranslation(l))
          })
          return langs.join(', ')
        }
        return '-'
      },
      transportation: function () {
        return this.parseArray(this.volunteer.transportation, this.$store.state.transportation, 'transportation') || '-'
      },
      availabilityTimes: function () {
        if (!this.volunteer.availabilityTimes) return []
        const bmArr = bitmaskhelper.bitmaskToArray(bitmaskhelper.translateEnumsToBitmask(this.volunteer.availabilityTimes))
        //return this.parseArray(this.volunteer.availabilityTimes, this.$store.state.availabilityTimes, 'availabilityTimes') || '-'
        // @ts-ignore cannot find mixins
        return this.parseArray(bmArr, this.$store.state.availabilityTimes, 'availabilityTimes') || '-'
      },
      phoneNumber: function () {
        return this.volunteer.phone || '-'
      }
    },
    mounted: function () {
      this.$bus.$on('clicked-search-result', this.handleClicked)
    },
    methods: {
      modalButtons: function () {
        return [{ text: this.$t('groups.removeVolunteer'), event: 'remove' }, { text: this.$t('groups.cancel'), event: 'cancel' }]
      },
      onRemove: function () {
        this.showRemove = false
        this.$emit('remove', this.volunteer)
      },
      clicked: function () {
        if (this.admin && !this.showRemove && !this.isStandardGroup) {
          this.open = !this.open
        }
      },
      makeGroupAdmin: function () {
        this.$bus.$emit('makeGroupAdmin', this.volunteer)
      },
      removeGroupAdmin: function () {
        this.$bus.$emit('removeGroupAdmin', this.volunteer)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';

  .buttons {
    padding-bottom: 15px;
  }

  .info{
    margin-top: 15px;
  }

  .volunteer-view {
    background: $brand-white;
    border: 2px solid $brand-white;
    &:hover
  {
    border: 2px solid $brand-red;
  }

  .portrait-size {
    height: 40px;
    width: 40px;
    margin: 10px;
  }

  .grid-row {
    display: grid;
    display: -ms-grid;
    grid-template-rows: 60px;
    -ms-grid-rows: 60px;
    grid-template-columns: 60px 1fr 200px;
    -ms-grid-columns: 60px 1fr 200px;
  }

  .grid-img {
    grid-row: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    -ms-grid-column: 1;
  }

  .more-info {
    color: $brand-grey10;
    text-transform: none;
  }

  .grid-name {
    grid-row: 1;
    -ms-grid-row: 1;
    grid-column: 2;
    -ms-grid-column: 2;
  }

  .grid-zip {
    grid-row: 1;
    -ms-grid-row: 1;
    grid-column: 3;
    -ms-grid-column: 3;
  }

  .style-zip {
    padding-top: 10px;
    text-align: right;
    span

  {
    line-height: 17px;
    overflow-wrap: break-word;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
  }

  }

  @media (max-width: $media-sm-max) {
    .grid-name {
      grid-row: 2;
      -ms-grid-row: 2;
      grid-column-start: 1;
      grid-column-end: 4;
      grid-column: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
    }
    .style-name {
      padding-top: 0 !important;
    }

    .text-right {
      text-align: left !important;
    }
  }

  .style-name {
    letter-spacing: 0.3px;
    font-weight: 700;
    color: $brand-red;
    text-transform: uppercase;
    font-family: "Signa-Light", Sans-Serif;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-top: 10px;
  }

  .isadmin {
    color: #84a763;
  }

  .style-img {
    line-height: 60px;
  }

  .action-link {
    cursor: pointer;
    &:hover
  {
    text-decoration: underline;
  }
  }
  }
</style>
