<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .groupmemberadd {
    background: #fff;
    padding-top: 20px;

    .savestatus {
      margin-bottom: 15px;
      .failed { color: $brand-red; }
      .success { color: $brand-success; }
      .noemail { color: $brand-success; }
    }

    .spr-searchbox {
      border: 1px solid $brand-grey4;
    }

    .button-row {
      margin-bottom: 40px;
      .spr-button {
        background: $brand-red;
        color: #fff;
      }
    }
    .spr-searchbox {
      margin-bottom: 20px;
    }
    .list-group {
      margin-bottom: 40px;
    }

    .results-row {
      .loading {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color:rgba(255, 255, 255, 0.8);

        .spinner {
          margin: auto;
          color:$brand-red;
        }
      }
      .user-row {
        background: #fff;
        margin-bottom: 5px;
        padding: 15px;
        border-bottom: 1px solid $brand-grey2;
        .user {
          .user-img {
            float: left;
            margin-right: 15px;
          }
          a {
            color: $brand-red;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
        .portrait-size {
          width: 50px;
          height: 50px;
        }
      }
    }
    .spacer {
      margin-bottom:20px;
    }
  }
</style>

<template>
  <div class="row groupmemberadd">
    <div v-if="saveStatus && saveStatus.length" class="col-sm-12 savestatus">
      <div v-for="status in saveStatus" :key="status.memberId">
        <div v-if="status.saveStatus === 0" class="failed">
          {{ $t('groups.failedAddingMember') }} {{ getMemberName(status.memberId) }}.
        </div>
        <div v-if="status.saveStatus === 1" class="success">
          {{ $t('groups.successAddingMember') }} {{ getMemberName(status.memberId) }}.
        </div>
        <div v-if="status.saveStatus === 2" class="noemail">
          {{ $t('groups.successFailedEmail') }} {{ getMemberName(status.memberId) }}
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="row">
        <div class="col-12">
          <searchbox v-model="searchText" :placeholder="placeholder" />
        </div>
      </div>
      <div class="row results-row">
        <div class="col-12">
          <volunteerslistsearch :list="volunteers"
                                :selected-volunteers="selectedVolunteers"
                                :is-saving="inProgress"
                                @addVolunteer="onAddVolunteer"
                                @removeVolunteer="onRemoveVolunteer"
                                @saveVolunteers="onSaveVolunteers"
          />
          <div v-show="isLoading" class="loading">
            <div class="spinner">
              <loading />
            </div>
          </div>
        </div>
      </div>
      <div v-show="showPager" class="row">
        <div class="col">
          <pager :skip="skip"
                 :take="take"
                 :total="total"
                 :max-display="5"
                 @pagesizechanged="onPageSizeChanged"
                 @pagerchanged="onPagerChanged"
                 @pagerprevious="onPagerPrevious"
                 @pagernext="onPagerNext"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="spacer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import searchbox from '../SPRSearchBox.vue'
  import volunteerslistsearch from './VolunteersSearchResultList.vue'
  import pager from '../SPRPager.vue'
  import loading from '../SPRLoading.vue'
  import anonPortrait from '../../assets/img/anon-portrait.png'

  export default {
    name: 'Groupmemberadd',
    components: { searchbox, volunteerslistsearch, pager, loading },
    props: {
      group: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        type: 'CUSTOMER',
        placeholder: this.$t('customerSearch.searchPlaceholder'),
        searchText: '',
        filter: '', // todo : eg 'district', should probably be from all if the group is for all users
        filterId: '',
        skip: 0,
        take: 10,
        total: 0,
        volunteer: {
          locations: [],
          interests: []
        },
        volunteers: [],
        selectedVolunteers: [],
        isLoading: false,
        inProgress: false,
        saveStatus: []
      }
    },
    computed: {
      showPager () {
        return this.total > this.take
      },
      portraitStyle: function () {
        var port = (this.$store.state.profile && this.$store.state.profile.portrait) ? this.$store.state.profile.portrait : anonPortrait
        return 'background: url(' + port + '); background-size: cover; background-position: center center;'
      }
    },
    watch: {
      searchText () {
        this.skip = 0
        this.doSearch()
      },
      group (updated, old) {
        this.doSearch()
      }
    },
    mounted () {
      this.doSearch()
      this.$bus.$on('group-member-removed', this.refreshLabels)
      this.$bus.$on('addPendingVolunteer', this.addedPendingVolunteer)
      this.$bus.$on('cancelAddPendingVolunteer', this.cancelAddPendingVolunteer)
      this.$bus.$on('removePendingVolunteer', this.removePendingVolunteer)
    },
    beforeDestroy: function () {
      this.$bus.$off('group-member-removed')
      this.$bus.$off('addPendingVolunteer')
      this.$bus.$off('cancelAddPendingVolunteer')
      this.$bus.$off('removePendingVolunteer')
    },
    methods: {
      onPageSizeChanged (pageSize) {
        this.take = pageSize
        this.performSearch()
      },
      onPagerChanged (page) {
        this.skip = page.skip
        this.doSearch()
      },
      onPagerPrevious () {
        this.skip -= this.take
        this.doSearch()
      },
      onPagerNext () {
        this.skip += this.take
        this.doSearch()
      },
      onAddVolunteer (volunteer) {
        var selectedVolunteer = _.find(this.volunteers, function (v) {
          return v.id === volunteer.id
        })
        selectedVolunteer.selected = 2
        this.$set(this.selectedVolunteers, this.selectedVolunteers.length, selectedVolunteer)
      },
      onRemoveVolunteer (volunteer) {
        var selectedVolunteer = _.find(this.volunteers, function (v) {
          return v.id === volunteer.id
        })

        if (selectedVolunteer) selectedVolunteer.selected = 0
        var index = _.findIndex(this.selectedVolunteers, function (v) {
          return v.id === volunteer.id
        })
        this.$delete(this.selectedVolunteers, index)
      },
      onSaveVolunteers: function () {
        if (this.inProgress) {
          return
        }
        this.inProgress = true
        this.saveStatus = []

        const self = this
        let payload = {
          payload1: this.group.id,
          payload2: this.selectedVolunteers,
          payload3: this.group.name
        }

        let uri = ''
        if (this.group.groupType === 1) {
          uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.grouprequestmembership[0]
        } else {
          payload = {
            payload1: this.group.id,
            payload2: _.map(this.selectedVolunteers, function (x) {
              return x.id
            })
          }

          uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupaddmembers[0]
        }

        this.$http.put(uri, payload).then(s => {
          s.body.forEach(x => {
            this.saveStatus.push(x)
          })
          self.selectedVolunteers = []
          self.inProgress = false
          self.$bus.$emit('loadGroup')

          // set a timeout on savestatus to clear after a bit
          setTimeout(() => {
            this.saveStatus = []
          }, 5000)
        }, f => {
          console.log('fail', f)
          self.inProgress = false
        })
      },
      doSearch () {
        var self = this
        self.isLoading = true
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.findGroupVolunteers[0]
          .replace('{search}', this.searchText)
          .replace('{skip}', this.skip)
          .replace('{take}', this.take)

        this.$http.get(url).then(result => {
          this.total = result.body.total
          this.volunteers = _.map(result.body.results, function (item) {
            return {
              id: item.id,
              name: `${item.lastName}, ${item.firstName}`,
              sectionId: item.sectionId,
              age: item.age,
              portrait: item.portrait,
              selected: self.isAddedAlready(item.id),
              pendingConsent: self.isPendingConsent(item.id),
              email: item.email
            }
          })

          this.isLoading = false
        })
      },
      refreshLabels: function () {
        const self = this
        this.volunteers.forEach(function (x) {
          x.selected = self.isAddedAlready(x.id)
          x.pendingConsent = self.isPendingConsent(x.id)
        })
      },
      isAddedAlready (id) {
        if (_.findIndex(this.group.members, function (item) {
          return item.id === id
        }) > -1) { return 1 }

        if (_.findIndex(this.selectedVolunteers, function (item) {
          return item.id === id
        }) > -1) {
          return 2
        }

        return 0
      },
      isPendingConsent (id) {
        if (_.findIndex(this.group.pendingMembers, function (item) {
          return item.id === id
        }) > -1) { return 1 }

        return 0
      },
      addedPendingVolunteer (volunteer) {
        this.saveStatus = []
        var selectedVolunteer = _.find(this.volunteers, function (v) {
          return v.id === volunteer.id
        })
        if (selectedVolunteer) {
          selectedVolunteer.selected = 1
          selectedVolunteer.pendingConsent = 0
        }
        this.group.members.push(volunteer)
      },
      cancelAddPendingVolunteer (volunteer) {
        this.saveStatus = []
        var selectedVolunteer = _.find(this.volunteers, function (v) {
          return v.id === volunteer.id
        })
        if (selectedVolunteer) {
          selectedVolunteer.selected = 0
          selectedVolunteer.pendingConsent = 1
        }

        const self = this
        _.find(this.group.members, function (v, index) {
          if (v.id === volunteer.id) {
            self.group.members.splice(index, 1)
          }
        })
      },
      removePendingVolunteer () {
        this.saveStatus = []
      },
      getMemberName (id) {
        var member = this.volunteers.find(x => x.id === id)
        if (member) return `${member.name}`
        return ''
      }
    }
  }
</script>
